/* eslint-disable no-undef */
import React from "react";
import { MDBBtn } from "mdbreact";
import PropTypes from "prop-types";

export default function ButtonCTA({ url, block = true }) {
	ButtonCTA.propTypes = {
		url: PropTypes.string,
		url: PropTypes.boolean,
	};

	if (block) {
		return (
			<MDBBtn
				color="secondary"
				block
				className="p-3 font-weight-bold text-white px-5 text-nowrap"
				target="_blank"
				rel="noreferrer"
				href={url}
				// eslint-disable-next-line no-unused-vars
				onClick={(e) => {
					if (typeof window !== "undefined") {
						// Triggers de Analytics
						// window.gtag("event", "conversion", { send_to: "AW-1002781011/X0ZICOOT6M8CENPylN4D", event_callback: "callback" })
						// window.gtag("Interesse", "a");
					}
				}}
			>
				Inscreva-se
			</MDBBtn>
		);
	} else {
		return (
			<MDBBtn
				color="secondary"
				className="p-3 font-weight-bold text-white px-5 text-nowrap"
				target="_blank"
				rel="noreferrer"
				href={url}
				// eslint-disable-next-line no-unused-vars
				onClick={(e) => {
					if (typeof window !== "undefined") {
						// Triggers de Analytics
						// window.gtag("event", "conversion", { send_to: "AW-1002781011/X0ZICOOT6M8CENPylN4D", event_callback: "callback" })
						// window.gtag("Interesse", "a");
					}
				}}
			>
				Inscreva-se
			</MDBBtn>
		);
	}
}
