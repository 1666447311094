/* eslint-disable no-undef */
import React, { useEffect } from "react";
import PropTypes from "prop-types";

import "bootstrap-css-only/css/bootstrap.min.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "../../../src/assets/mdbreact/scss/mdb-free.scss";
import "react-multi-carousel/lib/styles.css";

import { MDBContainer, MDBRow, MDBCol } from "mdbreact";

import Avisos from "../../components/Headers/Avisos/Avisos";
import MainNavbar from "../../components/MainNavbar/MainNavbar";
import Footer from "../../components/Footer/Footer";
import { GGgetMode } from "../../services/gglocalstorage";

export default function Layout({ children, color }) {
	Layout.propTypes = {
		children: PropTypes.object.isRequired,
	};

	useEffect(() => {
		if (
			process.env.NODE_ENV === "production" &&
			typeof window !== "undefined"
		) {
			// HandTalk
			let script = document.createElement("script");
			script.src = "//plugin.handtalk.me/web/latest/handtalk.min.js";
			script.async = true;
			script.onload = () => {
				new window.HT({
					token: "67d8ad24f158ff8b093ac95c14fb6b67",
					maxTextSize: 1000,
					pageSpeech: true,
				});
			};
			document.body.appendChild(script);

			// RD Station
			script = document.createElement("script");
			script.src =
				"https://d335luupugsy2.cloudfront.net/js/loader-scripts/168bf134-2e44-4e66-ab9a-cdb7f59837c2-loader.js";
			script.async = true;
			document.body.appendChild(script);

			//Suiteshare
			(function (s,u,i,t,e) {
				var share = s.createElement('script');
				share.async = true;
				share.id = 'suiteshare';
				share.src = 'https://static.suiteshare.com/widgets.js';
				share.setAttribute('init', i);
				s.head.appendChild(share);
				})( document, 'script', 'ef7845d350ca952280bfaf054cba8fca0ba37696');
		}
	});

	// TODO: ISSUE01 Revisar lógica de escape para licenciaturas e conheca-a-feevale-way
	return (
		<>
			<customCSS />
			<Avisos />
			<MainNavbar />
			<MDBContainer
				fluid
				style={{
					backgroundColor:
						GGgetMode() &&
						typeof window !== "undefined" &&
						!(
							window.location.pathname.startsWith("/licenciaturas") ||
							window.location.pathname.startsWith("/conheca-a-feevale-way")
						) &&
						"#080f07",
				}}
			>
				<MDBRow className="pb-5" style={{backgroundColor:color}}>
					<MDBCol style={{ paddingLeft: 0, paddingRight: 0 }}>
						{children}
					</MDBCol>
				</MDBRow>
			</MDBContainer>
			<Footer />
		</>
	);
}
