import React, { useState } from "react";
import { MDBFooter, MDBContainer, MDBRow, MDBCol, MDBIcon } from "mdbreact";

import { Link } from "gatsby";
import Logo from "../../assets/images/home/logo55.svg"
import Emec from "../../assets/images/footer/e-mec.jpg"

import BgVestibular from "../../assets/gg/images/home/bgVestibular.png";
import { GGgetMode } from "../../services/gglocalstorage";

/**
 * Barra de navegação superior.
 */

export default function Footer() {
	return (
		<MDBFooter color="indigo" className="font-small pt-4" style={{ backgroundImage: GGgetMode() && `url(${BgVestibular})`, backgroundSize: "cover"}}>
			<MDBContainer className="text-center text-md-left" style={{color:"#303030"}}>
				<MDBRow>
					<MDBCol md="4">
						<h5 className="title">Converse com a gente</h5>
						<MDBRow>
							<MDBCol className="text-center p-3">
								<a href="https://feevale.plusoftomni.com.br/api/chatsite/site/XVtMgYsroRAUYWhHsIeTq1qWYJ0aD5___CqiN1WNxxu8gwjKa2jtLA==/client" 
									className="px-3 btn rounded-circle bg-white" target="_blank" rel="noreferrer">
									<MDBIcon far icon="comment fa-4x" style={{color:"#303030"}}/>
								</a>
								<a href="https://whts.co/feevale-graduacao" target="_blank" rel="noreferrer" className="px-3 btn rounded-circle bg-white">
									<MDBIcon fab icon="whatsapp fa-4x" style={{color:"#303030"}}/>
								</a>
								<a href="https://www.instagram.com/feevale/" target="_blank" rel="noreferrer" className="px-3 btn rounded-circle bg-white">
									<MDBIcon fab icon="instagram fa-4x" style={{color:"#303030"}}/>
								</a>
							</MDBCol>
						</MDBRow>
						<MDBRow>
							<MDBCol className="p-3">
								<ul className="p-0 ml-md-5">
									<li className="list-unstyled">
										<a href="tel:555135868800" style={{color:"#303030"}} alt="telefone: (51) 3586.8800" title="(51) 3586.8800">(51) 3586.8800</a>
									</li>
									<li className="list-unstyled">
										<a href="mailto:falecomafeevale@feevale.br" style={{color:"#303030"}} alt="E-mail falecomafeevale@feevale.br">falecomafeevale@feevale.br</a>
									</li>
								</ul>
							</MDBCol>
						</MDBRow>
					</MDBCol>
					<MDBCol md="4">
						<h5 className="title">Acesso Rápido</h5>
						<ul className="p-0 ml-md-5">
							{/*<li className="list-unstyled">
								<Link className="text-white" to="/conheca-a-feevale-way">
									Conheça a Feevale Way
								</Link>
							</li>*/}
							<li className="list-unstyled">
								<Link style={{color:"#303030"}} to="/cursos/">
									Cursos
								</Link>
							</li>
							<li className="list-unstyled">
								<Link style={{color:"#303030"}} to="/descontos">
									Vantagens
								</Link>
							</li>
							<li className="list-unstyled">
								<Link style={{color:"#303030"}} to="/licenciaturas">
									Licenciaturas
								</Link>
							</li>
							<li className="list-unstyled">
								<Link style={{color:"#303030"}} to="/contato">
									Contato
								</Link>
							</li>
							<li className="list-unstyled">
								<a
									href="https://www.feevale.br"
									target="_blank"
									rel="noreferrer"
									style={{color:"#303030"}}
								>
									Universidade Feevale
								</a>
							</li>
						</ul>
					</MDBCol>
					<MDBCol md="4" style={{padding:"0px"}}>
						<a target="_blank" href="https://emec.mec.gov.br/emec/consulta-cadastro/detalhamento/d96957f455f6405d14c6542552b0f6eb/MjM=">
							<img src={Emec} className="" style={{border: "2px solid #303030", padding:"5px"}}/>
						</a>
					</MDBCol>
				</MDBRow>
			</MDBContainer>
			<div className="text-center py-3">
				<MDBContainer fluid>
					<a href="https://www.feevale.br" rel="noreferrer" target="_blank">
						<img
							src={Logo}
							alt="Universidade Feevale"
							className="img-fluid"
							style={{ maxWidth: "220px" }}
						/>
					</a>
					<p className="mt-3" style={{color:"#303030"}}>Todos os direitos reservados.</p>
				</MDBContainer>
			</div>
		</MDBFooter>
	);
}