import React, { useState } from "react";
import {
	MDBNavbar,
	MDBNavbarBrand,
	MDBNavbarNav,
	MDBNavItem,
	MDBNavbarToggler,
	MDBCollapse,
	MDBContainer,
	MDBBox,
	MDBRow,
	MDBCol,
	MDBIcon,
} from "mdbreact";

import { Link } from "gatsby";
import ButtonCTA from "../ButtonCTA/ButtonCTA";
import ModeSelect from "../GG/ModeSelect/ModeSelect";
import { GGgetMode } from "../../services/gglocalstorage";
import Logo from "../../assets/images/home/logo55.svg";

import "./MainNavbar.css";

/*
 * Barra de navegação superior.
 *Troquei o logo pelo dos  55 anos, não tirei o componente ali em cima, depois substituir por {Logo}
 */

export default function MainNavbar() {
	const [isMenuOpen, setIsMenuOpen] = useState(false);

	return (
		<MDBNavbar
			expand="md"
			color="indigo"
			dark
			className="text-center aling-itens-center"
		>
			<MDBContainer>
				<MDBNavbarBrand>
					<Link to={GGgetMode() ? "/gg/" : "/"}>
						<img
							src={Logo}
							alt="Universidade Feevale"
							className="img-fluid"
							style={{ maxWidth: "220px", height: "70px" }}
						/>
					</Link>
				</MDBNavbarBrand>
				<MDBNavbarToggler
					onClick={() => {
						setIsMenuOpen(!isMenuOpen);
					}}
					className="bg-secondary"
				/>
				<MDBCollapse id="navbarCollapse3" isOpen={isMenuOpen} navbar>
					<MDBNavbarNav left className="align-items-center">
						{/* {!GGgetMode() && (
							<MDBNavItem
								active
								className="d-flex align-items-center p-1 p-md-0 text-nowrap"
							>
								<Link
									className="mx-md-3 mx-lg-4 align-middle text-white"
									to="/conheca-a-feevale-way"
									activeClassName={
										"text-nowrap btn px-3 py-2 active text-nowrap" +
										(GGgetMode()
											? "btn-secondary btn-outline-secondary"
											: "btn-default btn-outline-default")
									}
								>
									Conheça a Feevale Way
								</Link>
							</MDBNavItem>
						)} */}
						<MDBNavItem active className="my-md-auto my-1">
							<Link
								className="mx-md-3 mx-lg-4"
								style={{color:"#303030"}}
								to="/cursos/"
								activeClassName={
									"active " +
									(GGgetMode()
										? "text-secondary font-weight-bold"
										: "text-secondary font-weight-bold")
								}
							>
								Cursos
							</Link>
						</MDBNavItem>
						<MDBNavItem active className="my-md-auto my-1">
							<Link
								className="mx-md-3 mx-lg-4"
								style={{color:"#303030"}}
								to={GGgetMode() ? "/gg/descontos" : "/descontos"}
								activeClassName={
									"active " +
									(GGgetMode()
										? "text-secondary font-weight-bold"
										: "text-secondary font-weight-bold")
								}
							>
								{GGgetMode() ? "Bolsas e descontos" : "Descontos"}
							</Link>
						</MDBNavItem>
						{/*{!GGgetMode() && (
							<MDBNavItem active className="my-md-auto my-1">
								<Link
									className="mx-md-3 mx-lg-4 text-white"
									to="/licenciaturas"
									activeClassName={
										"active " +
										(GGgetMode()
											? "text-white font-weight-bold"
											: "text-white font-weight-bold")
									}
								>
									Licenciaturas
								</Link>
								</MDBNavItem>
						)}*/}
						<MDBNavItem active className="my-md-auto my-1">
							<Link
								className="mx-md-3 mx-lg-4 align-middle"
								to={GGgetMode() ? "/gg/contato" : "/contato"}
								style={{color:"#303030"}}
								activeClassName={
									"active " +
									(GGgetMode()
										? "text-secondary font-weight-bold"
										: "text-secondary font-weight-bold")
								}
							>
								Contato
							</Link>
						</MDBNavItem>
						<MDBNavItem active className="my-md-auto my-1">
							<Link
							className="mx-md-3 mx-lg-4 align-middle"
							to="https://www.feevale.br/todas-as-oportunidades/intercambio"
							style={{color:"#303030"}}
							activeClassName={
									"active" +
									(GGgetMode()
									? "text-white font-weight-bold"
									: "text-white font-weight-bold")
							}
							target="blank">
								Intercâmbio
							</Link>
						</MDBNavItem>
						{/* <MDBNavItem className="d-flex align-items-center p-1 p-md-0">
								<a
									className="mx-md-3 mx-lg-4 align-middle text-decoration-none text-white"
									href="https://www.feevale.br"
									rel="noreferrer"
									target="_blank"
								>
									Já sou estudante
								</a>
							</MDBNavItem> */}
					</MDBNavbarNav>
					<MDBNavbarNav right className="py-2 p-md-0 ">
						<MDBNavItem className="my-3 my-md-0 col-12 col-md">
							<Link to={GGgetMode() ? "/gg/inscrevase" : "/inscrevase"}>
								<ButtonCTA key="btnInscricoesNavbar" />
							</Link>
						</MDBNavItem>
						{/* <MDBNavItem className="aling-middle my-auto d-md-flex">
							<ModeSelect />
						</MDBNavItem> */}
					</MDBNavbarNav>
				</MDBCollapse>
			</MDBContainer>
		</MDBNavbar>
	);
}

/*
<>
			<MDBRow>
				<MDBCol className="bg-primary">
					<MDBContainer>
						<MDBRow>
							<MDBCol col={8} md={10} className="d-flex">
								<Link to={GGgetMode() ? "/gg/" : "/"}>
									<img
										src={Logo}
										alt="Universidade Feevale"
										className="img-fluid"
										style={{ maxWidth: "160px", height: "70px" }}
									/>
								</Link>
							</MDBCol>
							<MDBCol col={4} md={2} className="text-center my-auto d-flex">
								<ModeSelect />
							</MDBCol>
						</MDBRow>
					</MDBContainer>
				</MDBCol>
			</MDBRow>
			<MDBRow>
				<MDBCol className="bg-white">
					<MDBContainer>
						<MDBRow>
							<MDBCol>
								<MDBNavbar className="shadow-none text-center" expand="md">
									<MDBNavbarToggler
										className="w-100"
										onClick={() => {
											setIsMenuOpen(!isMenuOpen);
										}}
									>
										<span className="h6">
											Menu principal{" "}
											<MDBIcon icon={isMenuOpen ? "times" : "chevron-down"} />
										</span>
									</MDBNavbarToggler>
									<MDBCollapse isOpen={isMenuOpen} navbar>
										<hr className="mb-1 mt-1" />
										<MDBNavbarNav className="text-dark text-center">
											
													<MDBNavItem active className="my-md-auto my-1">
													<Link
														className="mx-md-3 mx-lg-4 mainNavBar"
														to="/cursos/"
														activeClassName={
															"active " +
															(GGgetMode()
																? "text-secondary font-weight-bold"
																: "text-primary font-weight-bold")
														}
													>
														Cursos
													</Link>
												</MDBNavItem>
												<MDBNavItem className="my-md-auto my-1">
													<Link
														className="mx-md-3 mx-lg-4 mainNavBar"
														to={GGgetMode() ? "/gg/descontos" : "/descontos"}
														activeClassName={
															"active " +
															(GGgetMode()
																? "text-secondary font-weight-bold"
																: "text-primary font-weight-bold")
														}
													>
														{GGgetMode() ? "Bolsas e descontos" : "Descontos"}
													</Link>
												</MDBNavItem>
												{!GGgetMode() && (
													<MDBNavItem className="my-md-auto my-1">
														<Link
															className="mx-md-3 mx-lg-4 mainNavBar"
															to="/licenciaturas"
															activeClassName={
																"active " +
																(GGgetMode()
																	? "text-secondary font-weight-bold"
																	: "text-primary font-weight-bold")
															}
														>
															Licenciaturas
														</Link>
													</MDBNavItem>
												)}
												<MDBNavItem className="my-md-auto my-1">
													<Link
														className="mx-md-3 mx-lg-4 mainNavBar"
														to={GGgetMode() ? "/gg/contato" : "/contato"}
														activeClassName={
															"active " +
															(GGgetMode()
																? "text-secondary font-weight-bold"
																: "text-primary font-weight-bold")
														}
													>
														Contato
													</Link>
												</MDBNavItem>
												
												<MDBNavItem className="my-md-auto my-1 col-md text-center text-md-right">
													<Link
														to={GGgetMode() ? "/gg/inscrevase" : "/inscrevase"}
													>
														<ButtonCTA key="btnInscricoesNavbar" block={false} />
													</Link>
												</MDBNavItem>
											</MDBNavbarNav>
										</MDBCollapse>
									</MDBNavbar>
								</MDBCol>
							</MDBRow>
						</MDBContainer>
					</MDBCol>
				</MDBRow>
				<MDBRow>
					<MDBCol className="bg-primary" style={{ height: 10 }}></MDBCol>
				</MDBRow>
			</>
*/
